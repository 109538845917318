import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import UserModule from "@/store/module/user/UserModule";
import { routePath } from "@/router/router-constants";
import HandbookModule from "@/store/module/shared/HandbookModule";
import RoundUserAvatarMenu from "@/components/user/RoundUserAvatarMenu.vue";
import ApproveDialog from "@/components/shared/Approve.vue";
let UserDashboard = class UserDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.primaryDrawerModel = false;
        this.approve = {
            logout: false,
        };
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get menu() {
        return [
            {
                title: "Моя страница",
                show: true,
                route: `/user`,
            },
            {
                title: "События",
                show: true,
                route: "/user/events",
            },
            {
                title: "Сообщества",
                show: true,
                route: "/user/groups",
            },
        ];
    }
    logout() {
        this.$store.dispatch(UserModule.types.actions.LOGOUT);
        this.$router.push("/login");
    }
    async mounted() {
        await Promise.all([
            await this.$store.dispatch(UserModule.types.actions.GET_USER),
            await this.$store.dispatch(HandbookModule.types.actions.GET_HANDBOOK),
        ]);
        if (this.$route.path === routePath.user.ROOT) {
            this.$router.push(routePath.user.ROOT);
        }
    }
};
UserDashboard = __decorate([
    Component({
        components: {
            ApproveDialog,
            RoundUserAvatarMenu,
        },
    })
], UserDashboard);
export default UserDashboard;
