import { __decorate } from "tslib";
import UserModule from "@/store/module/user/UserModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import Vue from "vue";
import Component from "vue-class-component";
let RoundUserAvatarMenu = class RoundUserAvatarMenu extends Vue {
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get items() {
        return [
            {
                icon: "mdi-account",
                href: "/user/profile",
                title: "Профиль",
                click: () => { },
            },
            {
                icon: "mdi-cog",
                href: "/user/settings",
                title: "Настройки",
                click: () => { },
            },
            {
                icon: "mdi-exit-to-app",
                href: "#",
                title: "Выход",
                click: () => {
                    this.$emit("logout");
                },
            },
        ];
    }
    get avatar() {
        if (this.user && this.user.avatar) {
            return { src: `${IMAGE_SERVER_URL}/public/${this.user.avatar}` };
        }
        else {
            return { src: require("@/assets/default-avatar.jpg") };
        }
    }
};
RoundUserAvatarMenu = __decorate([
    Component({
        components: {},
    })
], RoundUserAvatarMenu);
export default RoundUserAvatarMenu;
